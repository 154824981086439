import {Button, Card, Form, Alert} from 'react-bootstrap';
import {useEffect, useState} from "react";
import config from "../../config.json";
import {useParams, useNavigate} from "react-router-dom";
import axios from 'axios'
import {useAccount, useBalance} from "wagmi";
import {formatNumber, roundEther, parseEther} from "../../utils/utils";
import {
  prepareWriteContract,
  writeContract,
  waitForTransaction,
} from "@wagmi/core";
import poolABI from '../../abi/TomoPool.json'

export default function Stake() {
  const {hash} = useParams()
  const { address }: any = useAccount();
  const [candidate, setCandidate] = useState<any>({});
  const [value, setValue] = useState("");
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [txHash, setTxHash] = useState('');
  const navigate = useNavigate()
  useEffect(() => {
    getCandidate()
  }, [address])
  const { data } = useBalance({ address });

  const getCandidate = async () => {
    const {data} = await axios.get(config.apiUrl + '/candidates/' + hash)
    setCandidate(data)
  }

  const onChangeValue = (text: string) => {
    setValue(text);
  };
  const handleSubmit = async (event: any) => {
    setFail(false)
    setSuccess(false)
    event.stopPropagation()
    event.preventDefault()
    try {
      //@ts-ignore
      const { request } = await prepareWriteContract({
        //@ts-ignore
        address: hash,
        abi: poolABI,
        functionName: "stake",
        //@ts-ignore
        args: [],
        //@ts-ignore
        value: parseEther(value),
      });
      //@ts-ignore
      const tx = await writeContract(request);
      const waitForTransactionData = await waitForTransaction({
        hash: tx.hash,
      });
      if (waitForTransactionData.status) {
        setTxHash(tx.hash);
        setSuccess(true)
      }
    } catch (e: any) {
      console.error(e);
      setFail(true)
    }
  }

  const routeChange = () => {
    return navigate('/candidate/' + candidate.name + '/' + hash)
  }
  return (
    <div className='row justify-content-center'>
      <div className='col-lg-5 col-md-7'>
        <Alert show={success} variant="success" onClose={() => setSuccess(false)} dismissible>
          <Alert.Heading>Success!</Alert.Heading>
          <p>
            Congratulations! You have successfully staked {value} VIC.
            More <Alert.Link rel="noopener" href={
            config.vicscan + "/tx/" + txHash
          }>transaction detail</Alert.Link>.
          </p>
        </Alert>
        <Alert show={fail} variant="danger" onClose={() => setFail(false)} dismissible>
          <Alert.Heading>Failed!</Alert.Heading>
          <p>
            Ops! There are something wrong. Please try again later
          </p>
        </Alert>
        <Card className='staking-content'>
          <Card.Header>
            Stake to {candidate.name}
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            <Card.Body>
              <Form.Group className="mb-3">
                <Form.Label>
                  Candidate: {candidate.hash}
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Staker: {address}
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Capacity: {candidate.capacity} / {candidate.maxCapacity} VIC
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Your Balance: {formatNumber(roundEther(parseFloat(data?.formatted || "0")))} VIC
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  placeholder="Amount"
                  onChange={(e) => onChangeValue(e.target.value)}
                  value={value}
                  type="number"
                  min={10}
                />
              </Form.Group>

              <Card.Footer className='text-center'>
              <Button type="submit" className='me-3 btn green-button'>Stake</Button>
              <Button className="btn btn-outline-green" type="button" onClick={routeChange}>Back</Button>
              </Card.Footer>
            </Card.Body>
          </Form>
        </Card>
      </div>
    </div>
  )
}