import React, {useEffect, useState} from "react";
import {useParams, NavLink, useSearchParams} from 'react-router-dom'
import config from '../../config.json'
import {useAccount} from "wagmi";
import ReactPaginate from 'react-paginate';
import {formatNumber, hiddenHash, roundEther} from '../../utils/utils'
import Moment from 'react-moment';

import {Row, Col, Tabs, Tab, Table, Button} from 'react-bootstrap';

import axios from 'axios'
import Countdown from "react-countdown";

export default function Candidate() {
  let { address, isConnected }: any = useAccount();
  const [queryParameters] = useSearchParams()
  if (queryParameters.has('staker')) {
    address = queryParameters.get('staker')
  }


  const [candidatesName, setCandidatesName] = useState<any>({});
  const [transactions, setTransactions] = useState([]);
  const [txTotalPage, setTxTotalPage] = useState(1);

  const [rewards, setRewards] = useState([]);
  const [rwTotalPage, setRwTotalPage] = useState(1);

  useEffect(() => {
    getCandidates()
    getRewards(1)
    getTransactions(1)
  }, [address])


  const getCandidates = async () => {
    let url = config.apiUrl + '/candidates'
    const {data} = await axios.get(url)

    const items: any = {}
    for (let i = 0; i < data.candidates.length; i++) {
      items[data.candidates[i].hash] = data.candidates[i].name
    }
    setCandidatesName(items)
  }
  const getTransactions = async (page: number) => {

    let url = config.apiUrl +'/transactions' + '?page=' + page + '&limit=20'
    if (queryParameters.has('staker')) {
      url += '&staker=' + address
    }
    const {data} = await axios.get(url)
    setTransactions(data.transactions)
    setTxTotalPage(Math.ceil(data.total / data.limit))
  }

  const getRewards = async (page: number) => {
      let url = config.apiUrl +'/transactions/withdrawal' + '?page=' + page + '&limit=20'
      if (queryParameters.has('staker')) {
          url += '&staker=' + address
      }
    const {data} = await axios.get(url)
    setRewards(data.withdrawals)
    setRwTotalPage(Math.ceil(data.total / data.limit))
  }

  const handlePageChange = async (page: string, event: any) => {
    const num = event.selected + 1
    switch (page) {
      case 'tx':
        await getTransactions(num)
        break
      case 'reward':
        await getRewards(num)
        break
      default:
    }
  }


  return (
    <div>
      <Row>
        <Col>
        <Tabs>
          <Tab eventKey="withdrawals" title="Withdrawals">
            <Table striped bordered hover variant="dark">
              <thead>
              <tr>
                <th>Unstake Tx</th>
                <th className='nonmobile'>Unstake Block</th>
                <th className='nonmobile'>Withdraw Block</th>
                <th className='nonmobile'>Withdraw Tx</th>
                <th>Candidate</th>
                <th>Capacity</th>
              </tr>
              </thead>
              <tbody>
              {rewards.map((w: any, i: number) => (
                  <tr key={i}>
                    <td><NavLink className='green-color'
                                 to={config.vicscan + '/tx/' + w.unstakeTx}>{hiddenHash(w.unstakeTx, 6)}</NavLink></td>
                    <td className='nonmobile'><NavLink className='green-color'
                                                       to={config.vicscan + '/block/' + w.blockUnstake}>{w.blockUnstake}</NavLink>
                    </td>
                    <td className='nonmobile'><NavLink className='green-color'
                                                       to={config.vicscan + '/block/' + w.blockWithdraw}>{w.blockWithdraw}</NavLink>
                    </td>
                    <td className='nonmobile'>
                      {w.withdrawTx ? (
                          <NavLink className='green-color' to={config.vicscan + '/tx/' + w.withdrawTx}>
                            {hiddenHash(w.withdrawTx, 6)}</NavLink>
                      ) : ''}
                    </td>
                    <td><NavLink className='green-color'
                                 to={config.vicscan + '/address/' + w.candidate}>{candidatesName[w.candidate] || hiddenHash(w.candidate, 6)}</NavLink>
                    </td>
                    <td>{formatNumber(w.capacity)} VIC</td>
                  </tr>
              ))}
              </tbody>
            </Table>


            <ReactPaginate
                onPageChange={(event) => handlePageChange('reward', event)}
                pageRangeDisplayed={5}
                pageCount={rwTotalPage}
                renderOnZeroPageCount={null}
                breakLinkClassName="page-link"
                marginPagesDisplayed={2}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
                hrefAllControls
            />
          </Tab>
          <Tab eventKey="transaction" title="Transactions">
            <Table striped bordered hover variant="dark">
              <thead>
              <tr>
                <th>Tx</th>
                <th>Staker</th>
                <th>Event</th>
                <th>Candidate</th>
                <th>Capacity</th>
                <th>Block</th>
                <th>Age</th>
              </tr>
              </thead>
              <tbody>
              {transactions.map((tx: any, i: number) => (
                  <tr key={i}>
                    <td><NavLink className='green-color' to={config.vicscan + '/tx/' + tx.hash}
                                 target='_blank'>{hiddenHash(tx.hash, 8)}</NavLink></td>
                  <td><NavLink className='green-color' to={config.vicscan + '/address/' + tx.staker} target='_blank'>{hiddenHash(tx.staker, 8)}</NavLink></td>
                  <td>{tx.event}</td>
                  <td>
                    <NavLink className='green-color'
                             to={config.vicscan + '/address/' + tx.candidate}>{candidatesName[tx.candidate] || hiddenHash(tx.candidate, 6)}</NavLink>
                  </td>
                  <td>{formatNumber(roundEther(tx.capacity))}</td>
                  <td><NavLink className='green-color' to={config.vicscan + '/block/' + tx.blockNumber} target='_blank'>{tx.blockNumber}</NavLink></td>
                  <td><Moment unix toNow>{tx.createdAt}</Moment></td>
                </tr>
              ))}
              </tbody>
            </Table>
            <ReactPaginate
              onPageChange={(event) => handlePageChange('tx', event)}
              pageRangeDisplayed={5}
              pageCount={txTotalPage}
              renderOnZeroPageCount={null}
              breakClassName='page-item'
              breakLinkClassName="page-link"
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
            />

          </Tab>
        </Tabs>
        </Col>
      </Row>
    </div>
  )
}