import configJson from "./config.json";
import { Chain } from "@wagmi/core";

export const vic = {
  id: 88,
  name: 'Viction',
  network: 'VIC',
  nativeCurrency: {
    "decimals": 18,
    "name": "Viction",
    "symbol": "VIC"
  },
  rpcUrls: {
    "public": {
      "http": [
        "https://rpc.viction.xyz"
      ]
    },
    "default": {
      "http": [
        "https://rpc.viction.xyz"
      ]
    }
  },
  blockExplorers: {
    "etherscan": {
      "name": "VicScan",
      "url": "https://vicscan.xyz"
    },
    "default": {
      "name": "VicScan",
      "url": "https://vicscan.xyz"
    }
  },
  contracts: {
    multicall3: {
      address: `0x${configJson.contracts.multicall3.address}`,
      blockCreated:
        configJson.contracts.multicall3.blockCreated,
    },
  },
} as const satisfies Chain;
export const PROJECT_ID = configJson.PROJECT_ID;

//  config network
export const CHAINS = [vic];
export const DEFAULT_CHAIN = vic;
export const DEFAULT_CHAIN_ID = DEFAULT_CHAIN.id;

export const MIN_STAKE = 10;

export const TX_FEE_STAKE = 0.0002; // 0.000055
// export const TX_FEE_WITHDRAWALS = 0.00015;      // 0.00011
