import React, {useState} from 'react';
import {Container, Navbar, Button, Modal} from 'react-bootstrap';
import { useAccount, useConnect, useDisconnect} from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import Logo from '../../asset/vicpool.png'
import {hiddenHash} from '../../utils/utils'
import {NavLink} from "react-router-dom";
import {FiSettings} from "react-icons/fi";
import { SlClose } from "react-icons/sl"

import styled from "styled-components"

export const Close = styled(SlClose)`
  position: absolute;
  right: 30px;
  font-size: 50px;
  cursor: pointer;
  &:hover {
    color: '${(props) => props.theme.thirdBackgroundColor}'
  }
`
export default function Header() {

  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect()
  const { open, setDefaultChain } = useWeb3Modal();
  const { address, connector, isConnected }: any = useAccount();
  const { disconnect } = useDisconnect()

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <header>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand>
            <NavLink to='/'>
              <img src={Logo} alt='TomPool' height={'45px'}/>
            </NavLink>
          </Navbar.Brand>

          <>
            {isConnected ? (
              <div>
                <NavLink to='/profile' className='me-2 btn green-button float-start'>Profile</NavLink>
                <a onClick={() => open()} className='btn green-button show-490 float-start'>
                  <span><FiSettings/></span>
                </a>
                <a onClick={() => open()} className='btn green-button hide-490 float-start'>
                  <span>{hiddenHash(address, 7)}</span>
                </a>
              </div>
            ) : (
              <Button className="connect-button btn green-button" onClick={() => open()}>Connect Wallet</Button>
              // <div>
              //   <Button className="connect-button btn green-button" onClick={handleShow}>Connect Wallet</Button>
              //
              //   <Modal
              //     show={show}
              //     onHide={handleClose}
              //     className="connect-wallet-modal"
              //   >
              //     <Modal.Header>
              //       <Modal.Title>Connect Wallet</Modal.Title>
              //       <Close onClick={handleClose} size={20} />
              //     </Modal.Header>
              //     <Modal.Body>
              //       {connectors.map((connector) => (
              //         <button
              //           className="connect-button btn green-button"
              //           disabled={!connector.ready}
              //           key={connector.id}
              //           onClick={() => connect({ connector })}
              //         >
              //           {connector.name}
              //           {!connector.ready && ' (unsupported)'}
              //           {isLoading &&
              //             connector.id === pendingConnector?.id &&
              //             ' (connecting)'}
              //         </button>
              //       ))}
              //
              //       {error && <div>{error.message}</div>}
              //     </Modal.Body>
              //     <Modal.Footer>
              //       <Button variant="primary" onClick={handleClose}>
              //         Close
              //       </Button>
              //     </Modal.Footer>
              //   </Modal>
              // </div>
            )}
          </>
        </Container>
      </Navbar>
    </header>
  );
}