import React, {useEffect, useState} from "react";
import {useParams, NavLink} from 'react-router-dom'
import config from '../../config.json'
import {useAccount, useContractReads} from "wagmi";
import ReactPaginate from 'react-paginate';
import poolABI from '../../abi/TomoPool.json'
import {formatNumber, hiddenHash, roundEther, formatEther} from '../../utils/utils'
import Moment from 'react-moment';

import {Row, Col, Button, Card, Tabs, Tab, Table, Dropdown, Alert} from 'react-bootstrap';

import axios from 'axios'
import {prepareWriteContract, waitForTransaction, writeContract} from "@wagmi/core";

export default function Candidate() {
  const {name, hash} = useParams()
  const { address, isConnected }: any = useAccount();

  const [candidate, setCandidate] = useState<any>({});

  const [stats, setStats] = useState<any>({});

  const [transactions, setTransactions] = useState([]);
  const [txTotalPage, setTxTotalPage] = useState(1);
  const [totalStaker, setTotalStaker] = useState(0);

  const [rewards, setRewards] = useState([]);
  const [rwTotalPage, setRwTotalPage] = useState(1);

  const [stakers, setStakers] = useState<any>([]);
  const [stTotalPage, setStTotalPage] = useState(1);

  const [poolData, setPoolData] = useState<any>([]);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [txHash, setTxHash] = useState('');

  const { data: poolContractData } = useContractReads({
    contracts: [
      {
        // @ts-ignore
        address: hash,
        //@ts-ignore
        abi: poolABI,
        functionName: "getCurrentStakerCap",
        args: [address],
      },
      {
        // @ts-ignore
        address: hash,
        //@ts-ignore
        abi: poolABI,
        functionName: "getStakerCurrentReward",
        args: [address],
      }
    ],
    //@ts-ignore
    // watch: false,
    onSuccess(data: any) {
      // console.log('load done')
      setPoolData(poolContractData)
    }
  });

  useEffect(() => {
    if (isConnected) {
      setPoolData(poolContractData)
    }
  }, [address])

  useEffect(() => {
    if (isConnected) {
      setPoolData(poolContractData)
    }
    getStats()
    getCandidate()
    getStakers(1)
    getRewards(1)
    getTransactions(1)
  }, [address])
  const getCandidate = async () => {
    const {data} = await axios.get(config.apiUrl +'/candidates/' + hash)
    setCandidate(data)
  }
  const getStats = async () => {
    const {data} = await axios.get(config.apiUrl +'/config/stats/' + hash)
    setStats(data)
  }
  const getTransactions = async (page: number) => {
      const {data} = await axios.get(config.apiUrl +'/transactions/candidate/' + hash + '?page=' + page + '&limit=20')
    setTransactions(data.transactions)
    setTxTotalPage(Math.ceil(data.total / data.limit))
  }

  const getRewards = async (page: number) => {
    const {data} = await axios.get(config.apiUrl +'/candidates/' + hash + '/rewards' + '?page=' + page + '&limit=20')
    setRewards(data.rewards)
    setRwTotalPage(Math.ceil(data.total / data.limit))
  }

  const getStakers = async (page: number) => {
    const {data} = await axios.get(config.apiUrl +'/candidates/' + hash + '/stakers' + '?page=' + page + '&limit=20')
    setStakers(data.stakers)
    setTotalStaker(data.total)
    setStTotalPage(Math.ceil(data.total / data.limit))
  }

  const handlePageChange = async (page: string, event: any) => {
    const num = event.selected + 1
    switch (page) {
      case 'staker':
        await getStakers(num)
        break
      case 'tx':
        await getTransactions(num)
        break
      case 'reward':
        await getRewards(num)
        break
      default:
    }
  }



  const handleClaimReward = async () => {
    setFail(false)
    setSuccess(false)

    if (config.blacklist.includes(address.toLowerCase())) {
      return setFail(true);
    }
    try {
      //@ts-ignore
      const { request } = await prepareWriteContract({
        //@ts-ignore
        address: hash,
        abi: poolABI,
        functionName: "withdrawAllRewardsOfStaker",
        args: [address],
      });
      //@ts-ignore
      const tx = await writeContract(request);
      const waitForTransactionData = await waitForTransaction({
        hash: tx.hash,
      });
      if (waitForTransactionData.status) {
        setTxHash(tx.hash);
        setSuccess(true)
      }
    } catch (e: any) {
      console.error(e);
      setFail(true)
    }
  }

  return (
    <div>
      <Row>
        <Col>
          <Card className='background-1 stats-box'>
            <Card.Body>
              <Card.Title>My Stake</Card.Title>
              <Card.Text>
                {poolData !== undefined && poolData.length > 0 && !poolData[0]?.error ? formatNumber(formatEther(poolData[0]?.result)) + ' ' : "0"} VIC
              </Card.Text>
              <Button className='green-color' variant="link" href={'/stake/' + candidate.hash}>Stake more</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className='background-1 stats-box'>
            <Card.Body>
              <Card.Title>My Rewards</Card.Title>
              <Card.Text>
                {poolData !== undefined && poolData.length > 0 && !poolData[1]?.error ? formatNumber(parseFloat(formatEther(poolData[1]?.result).toFixed(5))) + ' ' : "0"} VIC
              </Card.Text>
              <Button className='green-color' variant="link" href='#' onClick={handleClaimReward}>Withdraw</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className='background-1 stats-box'>
            <Card.Body>
              <Card.Title>Total Staker</Card.Title>
              <Card.Text>
                {totalStaker}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className='background-1 stats-box'>
            <Card.Body>
              <Card.Title>ROI</Card.Title>
              <Card.Text>
                {stats.stakingROI} %
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className='background-1 main-box'>
            <Card.Header>Pool: {name}</Card.Header>
            <Card.Body>
              <Card.Text>
                Candidate: <a className='green-color' target='_blank' href={ config.vicscan + '/address/' + candidate.hash}>{candidate.hash}</a>
              </Card.Text>
              <Card.Text>
                Coinbase: <a className='green-color' target='_blank' href={'https://master.tomochain.com/candidate/' + candidate.coinbase}>{candidate.coinbase}</a>
              </Card.Text>
              <Card.Text>
                Capacity {formatNumber(candidate.capacity || 0)}/{formatNumber(candidate.maxCapacity || 0)} VIC
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {isConnected &&
        <div className='button-box'>
          <NavLink className='btn green-button me-2' to={'/stake/' + hash}>Stake</NavLink>
          <NavLink className="btn btn-danger" to={'/unstake/' + hash}>Unstake</NavLink>{' '}

          <Dropdown className='float-end ms-2'>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              Other
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <NavLink className='dropdown-item' to={'/transfer-stake/' + hash}>Transfer Stake</NavLink>
              <NavLink className='dropdown-item' to={'/vote-resign/' + hash}>Vote Resign</NavLink>
              {/*<NavLink className='dropdown-item' to={'/unvote-resign/' + hash}>Unvote Resign</NavLink>*/}
            </Dropdown.Menu>
          </Dropdown>
      </div>
      }
      <Alert show={success} variant="success" onClose={() => setSuccess(false)} dismissible>
        <Alert.Heading>Success!</Alert.Heading>
        <p>
          Congratulations! You have successfully withdraw your rewards.
          More <Alert.Link rel="noopener" href={
          config.vicscan + "/tx/" + txHash
        }>transaction detail</Alert.Link>.
        </p>
      </Alert>
      <Alert show={fail} variant="danger" onClose={() => setFail(false)} dismissible>
        <Alert.Heading>Failed!</Alert.Heading>
        <p>
          Ops! There are something wrong. Please try again later
        </p>
      </Alert>


      <Row>
        <Col>
        <Tabs>
          <Tab eventKey="stakers" title="Stakers">
            <Table striped bordered hover variant="dark" >
              <thead>
              <tr>
                <th>Staker</th>
                <th>Capacity</th>
              </tr>
              </thead>
              <tbody>
              {stakers.map((staker: any, i: number) => (
                <tr key={i}>
                  <td><NavLink className='green-color' to={'/staker/' + hash + '/' + staker.staker}>{staker.staker}</NavLink></td>
                  <td>{formatNumber(roundEther(staker.capacity))}</td>
                </tr>
              ))}
              </tbody>
            </Table>

            <ReactPaginate
              onPageChange={(event) => handlePageChange('staker', event)}
              pageRangeDisplayed={5}
              pageCount={stTotalPage}
              renderOnZeroPageCount={null}
              breakClassName='page-item'


              breakLinkClassName="page-link"
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
            />
          </Tab>
          <Tab eventKey="rewards" title="Rewards">
            <Table striped bordered hover variant="dark">
              <thead>
              <tr>
                <th>Epoch</th>
                <th>Reward</th>
                <th>Staker num</th>
                <th>Capacity</th>
                <th>Annual interest</th>
                <th>Age</th>
              </tr>
              </thead>
              <tbody>
              {rewards.map((reward: any, i: number) => (
                <tr key={i}>
                  <td>{reward.epoch}</td>
                  <td>{reward.totalReward}</td>
                  <td>{reward.stakerNumber}</td>
                  <td>{formatNumber(reward.totalCapacity)}</td>
                  <td>

                    <span>{(Math.ceil((reward.totalReward * 48 * 30 * 12) / reward.totalCapacity * 100 * 100)) / 100 }% </span>
                    <small>({formatNumber(Math.ceil((reward.totalReward * 48 * 30 * 12) * 100 ) / 100) } VIC)</small>
                  </td>
                  <td><Moment unix toNow>{reward.rewardTime}</Moment></td>
                </tr>
              ))}
              </tbody>
            </Table>


            <ReactPaginate
              onPageChange={(event) => handlePageChange('reward', event)}
              pageRangeDisplayed={5}
              pageCount={rwTotalPage}
              renderOnZeroPageCount={null}
              breakLinkClassName="page-link"
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
            />
          </Tab>
          <Tab eventKey="transaction" title="Transactions">
            <Table striped bordered hover variant="dark">
              <thead>
              <tr>
                <th>Tx</th>
                <th>Staker</th>
                <th>Event</th>
                <th>Capacity</th>
                <th>Block</th>
                <th>Age</th>
              </tr>
              </thead>
              <tbody>
              {transactions.map((tx: any, i: number) => (
                <tr key={i}>
                  <td><NavLink className='green-color' to={config.vicscan + '/tx/' + tx.hash} target='_blank'>{hiddenHash(tx.hash, 8)}</NavLink></td>
                  <td><NavLink className='green-color' to={config.vicscan + '/address/' + tx.staker} target='_blank'>{hiddenHash(tx.staker, 8)}</NavLink></td>
                  <td>{tx.event}</td>
                  <td>{formatNumber(roundEther(tx.capacity))}</td>
                  <td><NavLink className='green-color' to={config.vicscan + '/block/' + tx.blockNumber} target='_blank'>{tx.blockNumber}</NavLink></td>
                  <td><Moment unix toNow>{tx.createdAt}</Moment></td>
                </tr>
              ))}
              </tbody>
            </Table>
            <ReactPaginate
              onPageChange={(event) => handlePageChange('tx', event)}
              pageRangeDisplayed={5}
              pageCount={txTotalPage}
              renderOnZeroPageCount={null}
              breakClassName='page-item'
              breakLinkClassName="page-link"
              marginPagesDisplayed={2}
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              hrefAllControls
            />

          </Tab>
        </Tabs>
        </Col>
      </Row>
    </div>
  )
}