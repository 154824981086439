import React, {useEffect, useState} from "react";
import {useParams, NavLink} from 'react-router-dom'
import config from '../../config.json'
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';


import {Container, Row, Col, Card, Tabs, Tab, Table} from 'react-bootstrap';

import axios from 'axios'
import {formatNumber, hiddenHash, roundEther} from "../../utils/utils";

export default function Staker() {
  const {candidateHash, stakerHash} = useParams()

  const [staker, setStaker] = useState<any>({});

  const [transactions, setTransactions] = useState([]);
  const [txTotalPage, setTxTotalPage] = useState(1);

  const [rewards, setRewards] = useState([]);
  const [rwTotalPage, setRwTotalPage] = useState(1);


  useEffect(() => {
    getStaker()
    getRewards(1)
    getTransactions(1)
  }, [])
  const getTransactions = async (page: number) => {
    const {data} = await axios.get(config.apiUrl +'/transactions/staker/' + stakerHash + '?page=' + page + '&limit=20&candidate=' + candidateHash)
    setTransactions(data.transactions)
    setTxTotalPage(Math.ceil(data.total / data.limit))
  }

  const getRewards = async (page: number) => {
    const {data} = await axios.get(config.apiUrl +'/stakers/' + stakerHash + '/rewards' + '?page=' + page + '&limit=20&candidate=' + candidateHash)
    setRewards(data.rewards)
    setRwTotalPage(Math.ceil(data.total / data.limit))
  }

  const getStaker = async () => {
    const {data} = await axios.get(config.apiUrl +'/candidates/' + candidateHash + '/staker/' + stakerHash)
    setStaker(data)
  }

  const handlePageChange = async (page: string, event: any) => {
    const num = event.selected + 1
    switch (page) {
      case 'tx':
        await getTransactions(num)
        break
      case 'reward':
        await getRewards(num)
        break
      default:
    }
  }

  return (
    <div className='container'>
      <Container>

        <Row className='mb-3'>
          <Col>
            <Card className='background-1 main-box'>
              <Card.Header>Staker: <NavLink className='green-color' to={config.vicscan + '/address/' + stakerHash} target='_blank'>{stakerHash}</NavLink></Card.Header>
              <Card.Body>
                <Card.Text>

                  <Row>
                    <Col>Total Stake: {formatNumber(roundEther(staker.capacity))} VIC</Col>
                    <Col>Balance: {formatNumber(roundEther(staker.tomo))} VIC</Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col>Last day: {formatNumber(roundEther(staker.dailyReward))} VIC</Col>
                    <Col>Last 7 days: {formatNumber(roundEther(staker.weeklyReward))} VIC</Col>
                    <Col>Last 30 days: {formatNumber(roundEther(staker.monthlyReward))} VIC</Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col>
            <Tabs>
              <Tab eventKey="rewards" title="Rewards">
                <Table striped bordered hover variant="dark">
                  <thead>
                  <tr>
                    <th>Epoch</th>
                    <th>Reward</th>
                    <th>Capacity</th>
                    <th>Annual interest</th>
                    <th>Age</th>
                  </tr>
                  </thead>
                  <tbody>
                  {rewards.map((reward: any, i: number) => (
                    <tr key={i}>
                      <td>{reward.epoch}</td>
                      <td>{reward.reward}</td>
                      <td>{formatNumber(reward.totalCapacity)}</td>
                      <td>
                        <span>{(Math.ceil((reward.reward * 48 * 30 * 12) / reward.capacity * 100 * 100)) / 100 }% </span>
                        <small>({(Math.ceil((reward.reward * 48 * 30 * 12) * 100 )) / 100 } VIC)</small>
                      </td>
                      <td><Moment unix toNow>{reward.rewardTime}</Moment></td>
                    </tr>
                  ))}
                  </tbody>
                </Table>


                <ReactPaginate
                  onPageChange={(event) => handlePageChange('reward', event)}
                  pageRangeDisplayed={5}
                  pageCount={rwTotalPage}
                  renderOnZeroPageCount={null}
                  breakClassName='page-item'
                  breakLinkClassName="page-link"
                  marginPagesDisplayed={2}
                  containerClassName="pagination justify-content-center"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  activeClassName="active"
                  hrefAllControls
                />
              </Tab>
              <Tab eventKey="transaction" title="Transactions">
                <Table striped bordered hover variant="dark">
                  <thead>
                  <tr>
                    <th>Hash</th>
                    <th>Event</th>
                    <th>Capacity</th>
                    <th>Block</th>
                    <th>Age</th>
                  </tr>
                  </thead>
                  <tbody>
                  {transactions.map((tx: any, i: number) => (
                    <tr key={i}>
                      <td><NavLink className='green-color' to={config.vicscan + '/tx/' + tx.hash}>{hiddenHash(tx.hash, 8)}</NavLink></td>
                      <td>{tx.event}</td>
                      <td>{formatNumber(tx.capacity)}</td>
                      <td><NavLink className='green-color' to={config.vicscan + '/block/' + tx.blockNumber}>{tx.blockNumber}</NavLink></td>
                      <td><Moment unix toNow>{tx.createdAt}</Moment></td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
                <ReactPaginate
                  onPageChange={(event) => handlePageChange('tx', event)}
                  pageRangeDisplayed={5}
                  pageCount={txTotalPage}
                  renderOnZeroPageCount={null}
                  breakClassName='page-item'
                  breakLinkClassName="page-link"
                  marginPagesDisplayed={2}
                  containerClassName="pagination justify-content-center"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  activeClassName="active"
                  hrefAllControls
                />

              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  )
}