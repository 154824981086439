import React, { useEffect, useState } from "react";
import {NavLink, useSearchParams} from 'react-router-dom'
import config from '../../config.json'
import {useAccount} from "wagmi";
import {formatNumber, roundEther} from '../../utils/utils'

import axios from 'axios'
export default function Dashboard() {
  let { address, isConnected }: any = useAccount();
  const [queryParameters] = useSearchParams()
  if (queryParameters.has('staker')) {
    address = queryParameters.get('staker')
  }

  const [candidates, setCandidates] = useState<
    any[]
  >([]);

  useEffect(() => {
    getCandidates()
  }, [address])
  const getCandidates = async () => {
    let url = config.apiUrl + '/candidates'
    if (isConnected) {
      url += '?staker=' + address
    }
    const {data} = await axios.get(url)
    setCandidates(data.candidates)
  }

  return (

    <div>
      {candidates.length === 0 ? (
        <div style={{ textAlign: "center" }}>No candidate data</div>
      ) : (
        candidates.map((candidate, i) => (
          <NavLink key={i} className="item" to={'candidate/' + candidate.name + '/' + candidate.hash}>
            <div className="item-icon png">
              <img src={'/pool/' + candidate.avatar} alt=""/>
            </div>
            <div className="item-info">
              <div className="item-name">
                {candidate.name}
                <span className="roi">
                 {['PENDING', 'RESIGNED', 'CANCEL'].includes(candidate.status) ? candidate.status : (
                   <span className="hide360px">ROI: {candidate.roiPercent}</span>
                 )}
                </span>

              </div>
              <div className="item-des">
                <div>
                  Capacity: {formatNumber(roundEther(candidate.capacity))} / {formatNumber(roundEther(candidate.maxCapacity))}<span className="hide360px"> VIC</span>
                </div>
                <div className="you-stake">
                  {candidates[i].youStaked > 0 ? 'You Stake: ' + formatNumber(roundEther(candidate.youStaked)) + ' VIC' : ''}
                </div>
                <div className="withdraw-waiting">
                  {candidates[i].withdrawWaiting > 0 ? 'Withdraw Waiting: ' + formatNumber(roundEther(candidate.withdrawWaiting)) + ' VIC' : ''}
                </div>
              </div>
            </div>
          </NavLink>
        ))
      )}
    </div>
  )
}