import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { ThemeProvider } from "styled-components";
import { lightTheme, GlobalStyles } from "./themes";

import { configureChains, WagmiConfig, createConfig } from "wagmi";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Container from 'react-bootstrap/Container';

import Candidate from "./pages/Candidate";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Stake from "./pages/Stake";
import Staker from "./pages/Staker";
import TransferStake from "./pages/TransferStake";
import Unstake from "./pages/Unstake";
import VoteResign from "./pages/VoteResign";
import UnvoteResign from "./pages/UnvoteResign";
import History from "./pages/History";

import { CHAINS, PROJECT_ID, DEFAULT_CHAIN } from "./contants";
import React from "react";
import ReactGA from 'react-ga'

ReactGA.initialize('G-NF46HHRD0T');
ReactGA.pageview(window.location.pathname + window.location.search);

const projectId = PROJECT_ID;
const chains = CHAINS;
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);
function App() {
  return (
    <div>
      <BrowserRouter>
        <WagmiConfig config={wagmiConfig}>
          <ThemeProvider theme={lightTheme}>
            <GlobalStyles theme={lightTheme} />
            <Header></Header>
            <Container>
              <Routes>
                <Route path="/candidate/:name/:hash" element={<Candidate />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/history" element={<History />} />
                <Route path="/stake/:hash" element={<Stake />} />
                <Route path="/staker/:candidateHash/:stakerHash" element={<Staker />} />
                <Route path="/transfer-stake/:hash" element={<TransferStake />} />
                <Route path="/unstake/:hash" element={<Unstake />} />
                <Route path="/vote-resign/:hash" element={<VoteResign />} />
                <Route path="/unvote-resign/:hash" element={<UnvoteResign />} />
              </Routes>
            </Container>
            <Footer/>
          </ThemeProvider>
        </WagmiConfig>
      </BrowserRouter>

      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        defaultChain={DEFAULT_CHAIN}
      />
    </div>
  );
}

export default App;
