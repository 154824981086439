import React, {useEffect, useState} from "react";
import {NavLink, useSearchParams} from 'react-router-dom'
import config from '../../config.json'
import {useAccount} from "wagmi";
import ReactPaginate from 'react-paginate';
import Countdown from 'react-countdown'

import {Row, Col, Button, Card, Table, Alert} from 'react-bootstrap';

import axios from 'axios'
import {formatNumber, hiddenHash} from "../../utils/utils";
import {prepareWriteContract, waitForTransaction, writeContract} from "@wagmi/core";
import poolABI from "../../abi/TomoPool.json";

export default function Profile() {
  let { address }: any = useAccount();
  const [queryParameters] = useSearchParams()
  if (queryParameters.has('staker')) {
    address = queryParameters.get('staker')
  }

  const [withdrawal, setWithdrawal] = useState([]);
  const [wdTotalPage, setWdTotalPage] = useState(1);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [txHash, setTxHash] = useState('');
  const [candidatesName, setCandidatesName] = useState<any>({});
  const [currentBlock, setCurrentBlock] = useState(1);

  const getCandidates = async () => {
    let url = config.apiUrl + '/candidates'
    const {data} = await axios.get(url)

    const items: any = {}
    for (let i = 0; i < data.candidates.length; i++) {
      items[data.candidates[i].hash] = data.candidates[i].name
    }
    setCandidatesName(items)
  }

  useEffect(() => {
    getCandidates()
    getWithdrawal(1)
  }, [address])

  const getWithdrawal = async (page: number) => {
    const {data} = await axios.get(config.apiUrl +'/stakers/' + address.toLowerCase() + '/withdraw?limit=20&page=' + page)
    setWithdrawal(data.listWithdraw)
    setWdTotalPage(Math.ceil(data.total / data.limit))
    setCurrentBlock(data.currentBlock)
  }

  const handlePageChange = async (event: any) => {
      await getWithdrawal(event.selected + 1)
  }
  const handleWithdraw = async (poolAddress: string, blockNumber: number) => {
    setFail(false)
    setSuccess(false)
    try {

      if (config.blacklist.includes(address.toLowerCase())) {
        return setFail(true);
      }
      //@ts-ignore
      const { request } = await prepareWriteContract({
        //@ts-ignore
        address: poolAddress,
        abi: poolABI,
        functionName: "withdrawStake",
        args: [blockNumber],
      });
      //@ts-ignore
      const tx = await writeContract(request);
      const waitForTransactionData = await waitForTransaction({
        hash: tx.hash,
      });
      if (waitForTransactionData.status) {
        setTxHash(tx.hash);
        setSuccess(true)
      }
    } catch (e: any) {
      console.error(e);
      setFail(true)
    }
  }

  return (
    <div>
      <Row className='mb-3'>
        <Col>
          <Card className='background-1 main-box'>
            <Card.Header>Staker: <NavLink className='green-color' to={config.vicscan + '/address/' + address}>{address}</NavLink></Card.Header>
          </Card>
        </Col>
      </Row>


      <Row>
        <Col>
          <Alert show={success} variant="success" onClose={() => setSuccess(false)} dismissible>
            <Alert.Heading>Success!</Alert.Heading>
            <p>
              Congratulations! You have successfully withdraw your stake.
              More <Alert.Link rel="noopener" href={
              config.vicscan + "/tx/" + txHash
            }>transaction detail</Alert.Link>.
            </p>
          </Alert>
          <Alert show={fail} variant="danger" onClose={() => setFail(false)} dismissible>
            <Alert.Heading>Failed!</Alert.Heading>
            <p>
              Ops! There are something wrong. Please try again later
            </p>
          </Alert>
          <h2 className='heading'>Withdrawal stake history</h2>
          <div className='tab-content'>
          <Table striped bordered hover variant="dark">
            <thead>
            <tr>
              <th>Unstake Tx</th>
              <th className='nonmobile'>Unstake Block</th>
              <th className='nonmobile'>Withdraw Block</th>
              <th className='nonmobile'>Withdraw Tx</th>
              <th>Candidate</th>
              <th>Capacity</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {withdrawal.map((w: any, i: number) => (
              <tr key={i}>
                <td><NavLink className='green-color' to={config.vicscan + '/tx/' + w.unstakeTx}>{hiddenHash(w.unstakeTx, 6)}</NavLink></td>
                <td className='nonmobile'><NavLink className='green-color' to={config.vicscan + '/block/' + w.blockUnstake}>{w.blockUnstake}</NavLink></td>
                <td className='nonmobile'><NavLink className='green-color' to={config.vicscan + '/block/' + w.blockWithdraw}>{w.blockWithdraw}</NavLink></td>
                <td className='nonmobile'>
                  {w.withdrawTx ? (
                    <NavLink className='green-color' to={config.vicscan + '/tx/' + w.withdrawTx}>
                      {hiddenHash(w.withdrawTx, 6)}</NavLink>
                  ) : ''}
                </td>
                <td><NavLink className='green-color' to={config.vicscan + '/address/' + w.candidate}>{candidatesName[w.candidate] || hiddenHash(w.candidate, 6)}</NavLink></td>
                <td>{formatNumber(w.capacity)} VIC</td>
                <td>
                  {w.isWithdraw === false || !w.hasOwnProperty('isWithdraw') ? (
                    currentBlock > w.blockWithdraw ?
                    <Button className='green-button' onClick={() => handleWithdraw(w.candidate, w.blockWithdraw)}>Withdraw</Button>
                      : <Countdown date={(w.blockWithdraw - currentBlock) * 2000 + Date.now()} />
                  ) : ''}
                </td>
              </tr>
            ))}
            </tbody>
          </Table>


          <ReactPaginate
            onPageChange={(event) => handlePageChange(event)}
            pageRangeDisplayed={5}
            pageCount={wdTotalPage}
            renderOnZeroPageCount={null}
            breakClassName='page-item'
            breakLinkClassName="page-link"
            marginPagesDisplayed={2}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            hrefAllControls
          />
          </div>
        </Col>
      </Row>
  </div>
  )
}