import {Button, Card, Form, Alert} from 'react-bootstrap';
import {useEffect, useState} from "react";
import config from "../../config.json";
import {useParams, useNavigate} from "react-router-dom";
import axios from 'axios'
import {useAccount, useContractReads} from "wagmi";
import {formatNumber, roundEther, formatEther} from "../../utils/utils";
import {
  prepareWriteContract,
  writeContract,
  waitForTransaction,
} from "@wagmi/core";
import poolABI from '../../abi/TomoPool.json'

export default function UnvoteResign() {
  const {hash} = useParams()
  const { address }: any = useAccount();
  const [candidate, setCandidate] = useState<any>({});
  const [stakeInfo, setStakeInfo] = useState<any>({});
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [txHash, setTxHash] = useState('');
  const navigate = useNavigate()
  const [poolData, setPoolData] = useState<any>([]);
  useEffect(() => {
    getCandidateAndStakeData()
    setPoolData(poolContractData)
  }, [address])

  const getCandidateAndStakeData = async () => {
    const {data} = await axios.get(config.apiUrl + '/stakers/' + address.toLowerCase() + '/candidate/' + String(hash).toLowerCase())
    setCandidate(data.candidate)
    setStakeInfo(data.candidateStaker)
  }

  const { data: poolContractData } = useContractReads({
    contracts: [
      {
        // @ts-ignore
        address: hash,
        //@ts-ignore
        abi: poolABI,
        functionName: "governance",
        args: [],
      },
    ],
    //@ts-ignore
    watch: true,
  });

  const handleSubmit = async (event: any) => {
    setFail(false)
    setSuccess(false)
    event.stopPropagation()
    event.preventDefault()
    try {
      //@ts-ignore
      const { request } = await prepareWriteContract({
        //@ts-ignore
        address: hash,
        abi: poolABI,
        functionName: "communityVote",
        args: [true],
      });
      //@ts-ignore
      const tx = await writeContract(request);
      const waitForTransactionData = await waitForTransaction({
        hash: tx.hash,
      });
      if (waitForTransactionData.status) {
        setTxHash(tx.hash);
        setSuccess(true)
      }
    } catch (e: any) {
      console.error(e);
      setFail(true)
    }
  }

  const routeChange = () => {
    return navigate('/candidate/' + candidate.name + '/' + hash)
  }
  return (
    <div className='row justify-content-center'>
      <div className='col-lg-5 col-md-7'>
        <Alert show={success} variant="success" onClose={() => setSuccess(false)} dismissible>
          <Alert.Heading>Success!</Alert.Heading>
          <p>
            Congratulations! You have successfully voted to resign.
            More <Alert.Link rel="noopener" href={
            config.vicscan + "/tx/" + txHash
          }>transaction detail</Alert.Link>.
          </p>
        </Alert>
        <Alert show={fail} variant="danger" onClose={() => setFail(false)} dismissible>
          <Alert.Heading>Failed!</Alert.Heading>
          <p>
            Ops! There are something wrong. Please try again later
          </p>
        </Alert>
        <Card className='staking-content'>
          <Card.Header>
            Vote resign at {candidate.name}
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            <Card.Body>
              <Form.Group className="mb-3">
                <Form.Label>
                  Candidate: {candidate.hash}
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Staker: {address}
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Capacity: {candidate.capacity} / {candidate.maxCapacity} VIC
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Your Stake: {formatNumber(roundEther(parseFloat(stakeInfo.capacity)))} VIC
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  Total vote resign: {poolData !== undefined && poolData.length > 0 && !poolData[0]?.error ? formatNumber(formatEther(poolData[0]?.result[1])) + ' ' : "0"} VIC
                </Form.Label>
              </Form.Group>

              <Card.Footer className='text-center'>
                <Button variant="danger" type="submit" className='me-3'>Vote Resign</Button>
                <Button className='btn btn-outline-green' type="button" onClick={routeChange}>Back</Button>
              </Card.Footer>
            </Card.Body>
          </Form>
        </Card>
      </div>
    </div>
  )
}